@import "https://fonts.googleapis.com/css?family=Poppins:600,500,400,300";
html, body {
  min-height: 100%;
  height: 100%;
  color: white;
  background-color: #000;
}

.transition-300, #change-avatar-area .change-avatar-btn, offline #offline-msg {
  transition: all 300ms ease !important;
}

#sidebar {
  background: #222;
  color: white;
}

#sidebar .logo-area {
  padding-left: 25px;
  padding-top: 40px;
}

#sidebar .logo-area span {
  text-transform: uppercase;
  color: #006C94;
  font-weight: lighter;
  font-size: 40px;
}

#sidebar .logo-area .user-type {
  color: #ccc;
  font-size: 15px;
}

#sidebar md-content {
  background: transparent !important;
}

#sidebar section {
  padding-left: 25px;
  margin-top: 75px;
  margin-bottom: 10px;
  color: #ccc;
  opacity: 0.5;
}

#sidebar .menu-item {
  color: white;
  padding: 10px 20px;
  cursor: pointer;
}

#sidebar .menu-item.active {
  background-color: #006c94;
}

#sidebar .menu-item[disabled] {
  opacity: 0.4;
}

#sidebar .menu-item md-icon {
  width: auto;
  height: auto;
  font-size: 30px;
  color: #ccc;
  margin: 0 17px 0 3px;
}

#sidebar .menu-item span {
  text-transform: uppercase;
  color: #ccc;
}

#sidebar .menu-item .user-time span {
  text-transform: none !important;
  font-weight: bold;
}

#sidebar .footer {
  background-color: black;
  margin: 0;
  padding: 15px 10px;
}

#sidebar .footer .avatar {
  width: auto;
  height: auto;
  max-width: 50px;
  max-height: 50px;
}

#sidebar .footer md-icon {
  color: #ccc;
}

.main-menu {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.main-menu li {
  margin-top: 0;
}

members-list table td {
  border-bottom: solid #eee 1px;
}

members-list table td:first-child {
  font-size: 22px;
  font-weight: 300;
  opacity: 0.3;
}

members-list .notes-td {
  position: relative;
}

members-list .notes-td.active {
  background: white;
  border: solid 1px #eee;
}

members-list .notes-td .text-fade, members-list .notes-td dashboard .order-history table td, dashboard .order-history table members-list .notes-td td {
  opacity: 0.4;
}

members-list .notes-td .notes-area {
  z-index: 100;
  position: absolute !important;
  top: 100%;
  right: 0;
  width: 300px;
  min-height: 200px;
  display: none;
  border: 0;
  padding: 10px;
  outline: none;
}

members-list .notes-td .notes-area.active {
  display: block;
}

.filters-bar {
  background: white;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15);
  padding: 0 10px;
}

.filters-bar input[type=text] {
  border: 0;
  height: 100%;
  padding: 0 10px;
  min-height: 50px;
  outline: none;
}

.filters-bar > * {
  margin: 0 5px;
}

mdp-time-picker .md-ink-ripple {
  display: none;
}

mdp-time-picker md-input-container {
  margin: 0;
  padding-left: 20px;
}

mdp-time-picker md-input-container input {
  border: 0 !important;
}

mdp-time-picker md-input-container .md-errors-spacer {
  display: none;
}

#members-data-menu-content md-menu-item {
  height: auto !important;
  max-height: none !important;
}

#members-data-menu-content md-menu-item .md-ink-ripple {
  margin: 0;
}

#members-data-menu-content md-menu-item md-input-container {
  margin: 0;
}

#members-data-menu-content md-menu-item md-input-container .md-errors-spacer {
  display: none;
}

.transition-1000 {
  transition: all 1s ease;
}

.text-accent {
  color: #006C94;
}

.text-warning {
  color: #ff6400;
}

.text-success {
  color: #00e600;
}

.text-danger {
  color: #ff6666;
}

.text-fade, dashboard .order-history table td {
  opacity: 0.5;
}

.text-center {
  text-align: center;
}

.no-bg {
  background: transparent;
}

.no-margin {
  margin: 0;
}

.bg-cover, #change-avatar-area {
  background-size: cover;
  background-position: center center;
}

.bg-danger {
  background: rgba(255, 0, 0, 0.1);
}

.bg-success {
  background: rgba(0, 128, 0, 0.1);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

.wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 0px;
}

@media (min-width: 600px) {
  .wrapper {
    padding: 30px;
  }
}

.full-page {
  position: relative;
  min-height: 100%;
}

.full-page .full-page-content {
  position: relative;
  z-index: 10;
}

.full-page .full-page-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}

.full-page .full-page-background:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: ' ';
  background-color: #222;
  opacity: 0.85;
}

.filters-bar {
  margin: 10px 0 40px 0;
  padding: 0;
}

.filters-bar > div {
  margin: 0;
  padding: 0 10px;
}

.filters-bar > div:first-child {
  border-bottom: solid 1px #eee;
}

.filters-bar md-select, .filters-bar md-switch, .filters-bar md-menu .md-button {
  margin: 0;
}

.filters-bar .filter-item {
  color: #999;
  border-left: solid 1px #eee;
  padding: 10px;
}

.filters-bar .filter-item input[type=date] {
  border: 0;
  color: #999;
}

.filters-bar .filter-item:first-child {
  border-left: 0;
}

h1, h2, h3 {
  font-weight: 300;
}

.avatar {
  max-width: 60px;
  max-height: 60px;
  border-radius: 600px;
}

.avatar-sm {
  max-width: 40px;
  max-height: 40px;
  border-radius: 40px;
}

.avatar-xs {
  max-width: 30px;
  max-height: 30px;
  border-radius: 30px;
}

.avatar-big {
  max-width: 100px;
  max-height: 100px;
  border-radius: 100px;
}

a, a:active, a:visited {
  color: #006C94;
  text-decoration: none;
}

.padding {
  padding: 20px;
}

.padding > md-card {
  margin-left: 0;
  margin-right: 0;
}

#loading-bar .bar {
  background: #006C94;
}

#loading-bar-spinner {
  left: 41px;
  top: 49px;
}

#loading-bar-spinner .spinner-icon {
  border-top-color: white !important;
  border-left-color: white !important;
  color: white !important;
}

.text-accent {
  color: #006C94;
}

.text-warning {
  color: #ff6400;
}

.text-success {
  color: #00e600;
}

.text-danger {
  color: #ff6666;
}

.text-fade, dashboard .order-history table td {
  opacity: 0.5;
}

.text-center {
  text-align: center;
}

.text-strike {
  text-decoration: line-through;
}

#change-avatar-area {
  min-width: 250px;
  min-height: 250px;
  max-width: 250px;
  max-height: 250px;
  border-radius: 250px;
  position: relative;
  margin-bottom: 50px;
}

#change-avatar-area .change-avatar-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 250px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  cursor: pointer;
}

#change-avatar-area:hover .change-avatar-btn {
  opacity: 1;
}

.coliseu-background {
  background: url("../assets/images/amigos_horizontal.jpg") no-repeat center center;
  background-size: cover;
}

/**********/
#main-toolbar {
  background-color: #000 !important;
  z-index: 20;
  height: auto !important;
  max-height: none !important;
}

#main-toolbar .md-toolbar-tools {
  height: auto !important;
  max-height: none !important;
}

/**********/
#login-view {
  color: white;
}

#login-view .full-page-background:after {
  opacity: 0;
}

#login-view h1 {
  color: #006C94;
  font-size: 50px;
  font-weight: 300;
  text-transform: uppercase;
}

#login-view md-card {
  background-color: rgba(34, 34, 34, 0.6);
  box-shadow: 0 0 2px 2px rgba(255, 255, 255, 0.2);
}

#login-view label {
  color: white;
  opacity: 0.8;
}

#login-view a {
  color: white;
  font-weight: bold;
  opacity: 0.7;
}

#login-view input[type=email], #login-view input[type=password] {
  border-color: rgba(255, 255, 255, 0.8);
  color: white;
}

#login-view .md-button {
  border: 1px solid rgba(0, 108, 148, 0.5);
  color: white;
}

#login-view small, #login-view p, #login-view md-icon {
  color: white;
}

/* CSS Document */
/* RESET */
/* FONTS */
/* GERAL */
body {
  font-family: 'Poppins', sans-serif;
  background: #FFF;
  color: #222;
  -webkit-font-smoothing: subpixel-antialiased;
}

.centrar {
  margin: 0 auto;
  display: table;
  vertical-align: top;
}

.topo {
  margin: 100px auto 0;
}

.subtopo {
  margin: 159px auto 0;
}

/* --------------- CORES --------------------- */
.principal, .principal ul, button:hover, .rodape, .rodape ul {
  background-color: #000;
}

#avancada a:hover, .submenu ul a {
  color: #36F;
}

.principal ul a, button:hover, .bts a, .rodape ul a, .card.grelha header, .secundario ul a {
  color: #999;
}

.principal ul li a.active {
  color: white;
}

.principal ul a:hover, .principal ul li:hover, .principal ul a.ativo, .bts a:hover, .rodape ul a:hover {
  color: #FFF;
}

.card.grelha {
  background-color: #222;
}

.card.grelha.ativo {
  background-color: #036;
}

.card.grelha:hover {
  background-color: #000;
}

.card.grelha:hover header {
  color: #fff;
}

.card.grelha.mes {
  background-color: #000;
}

.mes > header > h2 {
  color: #eee;
}

.detalhes, .secundario ul a:hover {
  color: #666;
}

.secundario {
  background-color: #ECECEC;
  padding: 0 50px;
}

.secundario ul a.ativo {
  color: #000;
  text-decoration: underline;
  font-weight: 600;
}

#vantagens {
  background-color: #0A4061;
}

/* --------------- MENUS ----------------- */
nav ul li {
  float: left;
  margin: 1.5rem 0.5rem 0;
  padding: 0.75rem;
  line-height: 1.5rem;
  min-width: 1.5rem;
}

nav ul li a {
  display: block;
  text-decoration: none;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

nav ul:after {
  content: "";
  clear: both;
  display: block;
}

nav ul ul {
  display: none;
  position: absolute;
  margin-left: -1.25rem;
  padding: 0.5rem 1rem;
  z-index: 9999;
  font-weight: 300;
}

nav ul ul li {
  float: none;
  padding: 0.25rem;
  margin-top: 0;
}

nav ul li:hover > ul {
  display: block;
}

/* --------------- MENU AMIGOS ----------------- */
nav#amigo {
  max-width: 80%;
  min-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

nav#amigo ul li {
  margin: 0 0.5rem 0 0;
  padding: 0.75rem;
  min-width: 1.8rem;
  min-height: 1.8rem;
  float: none;
  font-size: 1rem;
  letter-spacing: 0.05rem;
  transition: color 0.2s ease-out, border 0.3s ease-out;
  border-radius: 1.8rem;
  border: solid #fff 2px;
}

nav#amigo ul a:hover li, nav#amigo ul a li.active {
  color: #0FF;
  border-color: #0FF;
}

nav#amigo ul a li.active {
  color: #0FF;
  border-color: #0FF;
  background-color: #fff;
}

nav#amigo ul a {
  text-decoration: none;
  color: #fff;
}

nav#amigo ul a:hover, nav#amigo ul a li.active {
  color: #fff;
}

nav#amigo ul {
  /*list-style:none;*/
  padding: 0;
  margin: 2.2rem 0 0;
}

nav#amigo ul:after {
  content: "";
  clear: both;
  display: block;
}

nav#amigo ul li {
  padding: 0.25rem 1rem 0;
  margin: 0 0.5rem;
  display: inline-block;
}

/* --------------- MENU PRINCIPAL ----------------- */
.menumobile {
  display: none;
}

.principal {
  top: 0;
  width: 100%;
  z-index: 9998;
}

.submenu {
  width: 100%;
  top: 3em;
  z-index: 9997;
}

nav ul li.logo {
  margin-top: 0;
  padding: 0.3rem;
}

/* --------------- MENU SECUNDARIO ----------------- */
.secundario {
  top: 97px;
  width: 100%;
  z-index: 9997;
  box-shadow: 0px 1px 4px #999;
  overflow: auto;
}

.secundario > div {
  margin: 0.6rem 1rem;
}

.secundario > div > a {
  color: #999;
}

.secundario > div > a.active {
  color: #000;
  text-decoration: underline;
  font-weight: 600;
}

/* --------------- MENU FOOTER ----------------- */
.rodape ul {
  font-size: 80%;
}

/* --------------- DESTAQUES ----------------- */
#destaques {
  width: 640px;
  height: 460px;
  float: left;
  overflow: hidden;
}

#destaques-2 {
  width: 320px;
  height: 460px;
  float: left;
  overflow: hidden;
}

.destak img {
  max-height: 100%;
  /*width:auto;
    height:auto;*/
}

.flex-control-nav {
  text-align: center;
}

/* --------------- AGENDA ----------------- */
section.centrar {
  width: 960px;
}

.card {
  float: left;
  clear: none;
  display: block;
}

.card.grelha header {
  height: 110px;
  width: 136px;
  margin: 12px 15px 3px;
  overflow: hidden;
}

.card h1, .card h2 {
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 3px;
}

.card h2 {
  font-size: 120%;
}

.card h3 {
  line-height: 1.75rem;
  display: inline;
  font-size: 0.8rem;
}

.card h5, .card h4 {
  font-weight: 300;
  line-height: 140%;
  font-size: 80%;
}

.card.grelha {
  width: 159px;
  height: 159px;
  margin: 0;
  border-right: 1px solid #666;
  border-bottom: 1px solid #666;
}

nav ul.bts {
  height: 40px;
  width: 157px;
}

nav ul.bts li {
  height: 40px;
  width: 68px;
  margin: 0;
  padding: 5px;
  text-align: center;
  font-size: 130%;
}

/* --------------- APOIOS ----------------- */
.apoios {
  padding-top: 30px;
}

.apoios img {
  margin: 20px;
  width: auto;
}

.infoline {
  color: #eee;
  float: right;
  font-size: 120%;
}

/* --------------- EVENTO ----------------- */
article.evento header {
  margin: 30px 20px 30px 0;
  width: 600px;
  float: left;
}

.titulo {
  font-size: 350%;
  font-weight: 600;
}

.data, .hora {
  font-size: 150%;
  margin-top: 10px;
  font-weight: 400;
}

.cartaz {
  float: right;
  width: 320px;
  margin: 30px 0 30px 20px;
}

.detalhes {
  line-height: 140%;
  margin: 20px 0;
}

.bilhetes, .descontos {
  margin: 20px 0;
}

.descontos {
  margin-bottom: 60px;
}

.sinopse {
  font-size: 110%;
  line-height: 140%;
  font-weight: 300;
  margin: 50px 0 0;
}

.sinopse p {
  line-height: 140%;
}

.tague {
  font-weight: 600;
}

.comprar {
  display: block;
}

.comprar a li {
  display: inline-block;
  font-size: 200%;
  text-align: center;
  background-color: #666;
  color: #eee;
  padding: 10px;
  width: 30px;
  -webkit-border-top-left-radius: 25px;
  -webkit-border-top-right-radius: 25px;
  -moz-border-radius-topleft: 25px;
  -moz-border-radius-topright: 25px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  -webkit-border-bottom-left-radius: 25px;
  -webkit-border-bottom-right-radius: 25px;
  -moz-border-radius-bottomleft: 25px;
  -moz-border-radius-bottomright: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  text-decoration: none;
}

.comprar a li:hover {
  background-color: #09F;
}

.arquivo {
  margin: 60px auto;
  width: 920px;
}

.franja {
  width: 100%;
  height: 350px;
  overflow: hidden;
  text-align: center;
}

.evento {
  z-index: 9996;
}

@media screen and (min-width: 1280px) {
  nav ul li {
    margin-left: 1.1rem;
    margin-right: 1.1rem;
  }
  nav ul ul {
    padding: 0.5rem 0;
    margin-left: -1.35rem;
  }
  nav ul li.logo {
    width: 300px;
    text-align: center;
  }
  section.centrar {
    width: 1200px;
  }
  .card.grelha header {
    height: 135px;
    width: 156px;
    margin: 15px 20px 3px;
  }
  .card h1 {
    font-size: 110%;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .card h2 {
    font-size: 140%;
    font-weight: 400;
  }
  .card h5, .card h4 {
    font-weight: 300;
    line-height: 140%;
    font-size: 90%;
  }
  .card.grelha {
    width: 199px;
    height: 199px;
  }
  nav ul.bts {
    width: 197px;
  }
  nav ul.bts li {
    width: 88px;
    height: 35px;
  }
  article.evento header {
    margin: 30px 20px 30px 0;
    width: 840px;
  }
  .sinopse {
    width: 720px;
    float: left;
  }
  .extras {
    width: 320px;
    margin: 50px 0 0 100px;
    float: right;
  }
  #destaques {
    width: 880px;
    height: 480px;
  }
  .destak img {
    width: 100%;
    max-height: none;
  }
  #destaques-2 {
    height: 480px;
  }
  .bilhetes, .descontos {
    width: 400px;
    float: left;
  }
}

li.circo a {
  color: #F0F;
  border-bottom: 2px dotted #909;
  border-top: 2px dotted #909;
}

li.circo:hover a {
  color: #FFF;
  border-bottom: 2px dotted #FFF;
  border-top: 2px dotted #FFF;
}

.passagem {
  width: 100%;
  height: 50px;
  background: #fff;
  /* For browsers that do not support gradients */
  /* For Safari 5.1 to 6.0 */
  /* For Opera 11.1 to 12.0 */
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(#036, white);
  /* Standard syntax */
}

/* ------------------ AMIGOS -------------------- */
#amigos {
  margin: 0;
  background-image: url("../assets/images/amigos_horizontal.jpg");
  background-position: center;
  background-size: cover;
  min-height: 100vh;
}

#amigos.centrar {
  width: 100%;
}

#copyamigos {
  display: block;
  text-align: center;
  position: relative;
  z-index: 999;
  width: 800px;
  margin-left: auto;
  margin-top: 30vh;
  margin-right: auto;
}

#copyamigos h1, .formz h1 {
  font-size: 3rem;
  line-height: 1.2em;
  color: #fff;
  font-weight: 600;
  margin: 0 0 2.2rem;
  /*text-shadow: 1px 1px 3px #000;*/
}

.vantagem h1 {
  font-size: 2rem;
  line-height: 1.2em;
  color: #fff;
  font-weight: 600;
  margin: 0 0 1.7rem;
  /*text-shadow: 1px 1px 3px #000;*/
}

#copyamigos h4, .vantagem h4 {
  font-size: 1.2rem;
  line-height: 1.4em;
  color: #FFF;
  font-weight: 300;
}

#formulario, #vantagens {
  width: 100%;
  /*max-height:640px;*/
  text-align: center;
}

#formulario {
  text-align: center;
  background-color: #222;
}

.formz {
  width: 50%;
  padding: 200px 25%;
}

.vantagem.um {
  margin-top: 200px;
}

.vantagem.nove {
  margin-bottom: 200px;
  border: 0;
}

#login, #login .rodape, #login .rodape ul {
  background-color: #0A4061;
  color: #fff;
}

#login .rodape ul li {
  margin: 0.5rem;
  padding: 0.75rem;
  line-height: 1em;
}

#login .rodape ul a {
  color: #69C;
}

#login {
  width: 100%;
  height: 200px;
  /*max-height:640px;*/
  text-align: center;
  overflow: hidden;
  padding: 150px 0;
}

.vantagem {
  width: 50%;
  padding: 5%;
  margin: auto 20%;
  border-bottom: solid #39C 1px;
}

.vantagem h1, .vantagem h4 {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.nwsltr {
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  padding: 0.3rem 1rem;
  color: #222;
  -webkit-border-top-left-radius: 2rem;
  -webkit-border-top-right-radius: 2rem;
  -moz-border-radius-topleft: 2rem;
  -moz-border-radius-topright: 2rem;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  -webkit-border-bottom-left-radius: 2rem;
  -webkit-border-bottom-right-radius: 2rem;
  -moz-border-radius-bottomleft: 2rem;
  -moz-border-radius-bottomright: 2rem;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  background-color: #fff;
}

.log {
  font-size: 1.2rem;
}

@media (min-width: 600px) {
  .comprido {
    width: 600px;
  }
}

.curto {
  width: 200px;
}

.medio {
  width: 340px;
}

.formz .rodape ul li {
  margin: 0 0;
}

.formz .rodape ul, .formz .rodape {
  background-color: transparent;
}

.formz button[type=submit] {
  margin-top: 50px;
}

.formz h4 {
  color: white;
  margin-top: 100px;
}

.formz ul li[layout=row] input:first-child {
  max-width: 265px;
}

.formz ul li[layout=row] input:last-child {
  margin-left: 30px;
}

.formz .preferencias li {
  color: white;
}

.botoa {
  color: #fff;
  background-color: transparent;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  padding: 0.3rem 1rem 0.1rem;
  font-size: 1.2rem;
  -webkit-border-top-left-radius: 4rem;
  -webkit-border-top-right-radius: 4rem;
  -moz-border-radius-topleft: 4rem;
  -moz-border-radius-topright: 4rem;
  border-top-left-radius: 4rem;
  border-top-right-radius: 4rem;
  -webkit-border-bottom-left-radius: 4rem;
  -webkit-border-bottom-right-radius: 4rem;
  -moz-border-radius-bottomleft: 4rem;
  -moz-border-radius-bottomright: 4rem;
  border-bottom-left-radius: 4rem;
  border-bottom-right-radius: 4rem;
  border: #FFF solid 2px;
  cursor: pointer;
}

.botoa:not([disabled]):hover {
  color: #0FF;
  border-color: #0FF;
  background-color: transparent;
}

.botoa[disabled] {
  opacity: 0.4;
}

.botoa[disabled]:hover {
  background-color: initial;
  color: white;
}

@media screen and (max-width: 975px) {
  #amigos {
    background-image: url(../assets/images/amigos_vertical.jpg);
  }
  .topo {
    margin: 0 auto;
  }
  .subtopo {
    margin: 80px auto 0;
  }
  .principal, .mob {
    display: none;
  }
  .menumobile, .mob.open {
    display: block;
    z-index: 9999;
  }
  .mob {
    margin-bottom: 40px;
  }
  .submenu {
    width: 100%;
    top: 3em;
    z-index: 9997;
  }
  #hamburger {
    width: 20px;
    height: 20px;
    left: 20px;
    position: absolute;
    margin: 30px auto;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    float: left;
  }
  #hamburger span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #999;
    border-radius: 1px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }
  #hamburger span:nth-child(1) {
    top: 0px;
  }
  #hamburger span:nth-child(2), #hamburger span:nth-child(3) {
    top: 6px;
  }
  #hamburger span:nth-child(4) {
    top: 12px;
  }
  nav ul li {
    float: none;
    margin: 1.5rem 0.5rem 0;
    line-height: 1.5rem;
  }
  nav ul li a, nav ul a {
    text-decoration: none;
  }
  nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 240px;
    text-align: center;
  }
  nav ul ul {
    display: none;
    margin-left: -1.25rem;
    padding: 0.5rem 1rem;
    z-index: 9999;
    font-weight: 300;
  }
  nav ul ul li {
    padding: 0.25rem;
    margin-top: 0;
  }
  .menumobile, .menumobile ul, button:hover, .rodape, .rodape ul {
    background-color: #000;
  }
  #avancada a:hover, .submenu ul a {
    color: #36F;
  }
  .menumobile ul a, button:hover, .bts a, .rodape ul a, .card.grelha header, .secundario ul a {
    color: #999;
  }
  .menumobile ul a:hover, .menumobile ul li:hover, .menumobile ul a.ativo, .bts a:hover, .rodape ul a:hover {
    color: #FFF;
  }
  .card.grelha {
    background-color: #222;
  }
  .card.grelha.ativo {
    background-color: #036;
  }
  .card.grelha:hover {
    background-color: #000;
  }
  .card.grelha:hover header {
    color: #fff;
  }
  .card.grelha.mes {
    background-color: #000;
  }
  .mes > header > h2 {
    color: #eee;
  }
  .detalhes, .secundario ul a:hover {
    color: #666;
  }
  .secundario {
    background-color: #ECECEC;
    position: static;
  }
  .secundario ul a.ativo {
    color: #000;
    text-decoration: underline;
    font-weight: 600;
  }
  #copyamigos {
    width: 80vw;
    margin-left: 10vw;
    margin-top: 20vh;
    margin-right: 10vw;
  }
  nav#amigo {
    min-width: 300px;
  }
  .vantagem {
    width: 70%;
    padding: 5%;
    margin: auto 10%;
    border-bottom: solid #39C 1px;
  }
  .formz {
    width: 70%;
    padding: 200px 5%;
  }
}

#footer input {
  border-width: 0;
}

.sub-toolbar {
  z-index: 10;
  position: relative;
  background-color: #eee;
  padding: 0 10px;
}

.sub-toolbar .amigo-btn {
  padding: 5px 10px;
}

.sub-toolbar .amigo-btn img {
  margin-right: 10px;
  max-width: 30px;
  max-height: 30px;
}

@media (min-width: 600px) {
  .sub-toolbar .amigo-btn img {
    margin-right: 0;
    margin-left: 10px;
    max-width: 40px;
    max-height: 40px;
  }
}

dashboard #one-more-step {
  padding: 0;
  margin: 0;
}

dashboard #one-more-step > md-content {
  padding: 20px;
  margin: 0;
  width: 100%;
  height: 100%;
  color: white;
  background: url("../assets/images/amigos_horizontal.jpg");
  background-size: cover;
  background-position: center center;
}

dashboard #one-more-step h1 {
  font-size: 50px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}

dashboard #one-more-step h3 {
  text-align: center;
  font-weight: 300;
  font-size: 30px;
}

dashboard .order-history h1 {
  font-size: 50px;
  font-weight: 300;
  color: #168471;
  opacity: 0.7;
}

dashboard .order-history p {
  font-size: 22px;
  font-weight: 300;
  opacity: 0.8;
  text-align: right;
}

dashboard .order-history p small {
  font-size: 14px;
}

dashboard .order-history table td {
  border-bottom: solid 1px #ccc;
  padding: 10px;
}

#subscription-form {
  background-color: black;
  color: white;
  padding: 50px 0;
}

#subscription-form > div {
  margin: 0 20px;
}

#subscription-form .submit-area {
  margin: 40px 0;
}

#subscription-form input[type='text'], #subscription-form input[type=password], #subscription-form input[type=email], #subscription-form input[type=date] {
  margin: 10px 0;
  width: 100%;
  outline: none;
}

@media (min-width: 600px) {
  #subscription-form input[type='text'], #subscription-form input[type=password], #subscription-form input[type=email], #subscription-form input[type=date] {
    width: 600px;
  }
  #subscription-form div[layout-gt-xs="row"] input[type='text']:first-child, #subscription-form div[layout-gt-xs="row"] input[type=password]:first-child, #subscription-form div[layout-gt-xs="row"] input[type=email]:first-child, #subscription-form div[layout-gt-xs="row"] input[type=date]:first-child {
    width: 250px;
  }
  #subscription-form div[layout-gt-xs="row"] input[type='text']:last-child, #subscription-form div[layout-gt-xs="row"] input[type=password]:last-child, #subscription-form div[layout-gt-xs="row"] input[type=email]:last-child, #subscription-form div[layout-gt-xs="row"] input[type=date]:last-child {
    width: 340px;
    margin-left: 10px;
  }
}

#subscription-form h4 {
  margin-top: 60px;
}

#subscription-form h1 {
  text-transform: uppercase;
  font-size: 40px;
  letter-spacing: 2px;
}

#subscription-form md-checkbox {
  background-color: #fff;
  margin: 0 3px 3px 0;
}

#subscription-form md-checkbox .md-label {
  margin-left: 20px;
}

home-view input {
  background-color: #eee;
  border-width: 0;
}

input.error, input.ng-invalid-required.ng-touched {
  border-color: #ff6666;
  background-color: #ffcccc;
}

#footer > nav {
  padding-bottom: 20px;
  padding-top: 20px;
}

#footer a {
  margin: 20px;
  color: white;
  opacity: 0.7;
}

offline {
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
}

offline #offline-msg {
  width: 100%;
  background: #222;
  padding: 20px;
  margin-top: -100px;
  letter-spacing: 1px;
  font-size: 18px;
  border-radius: 10px;
}

offline #offline-msg, offline #offline-msg md-icon {
  color: #ff9999;
}

offline #offline-msg.active {
  margin-top: 0;
}

.quotas-table {
  margin-top: 30px;
  width: 100%;
}

.quotas-table th {
  color: #999;
  font-weight: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 13px;
}

.quotas-table td {
  font-size: 15px;
  border-bottom: solid 1px #eee;
  color: #666;
}

.quotas-table th, .quotas-table td {
  padding: 5px 10px;
  text-align: left;
}

.badge {
  background-color: #ccc;
  border-radius: 6px;
  padding: 2px 5px;
}

.badge.badge-success {
  background-color: #168471;
  color: white;
}

.badge.badge-warning {
  background-color: #ff6400;
  color: white;
}

.view, member-view, member-details, member-quotas, settings-view, quota-settings-view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.view > ui-view, member-view > ui-view, member-details > ui-view, member-quotas > ui-view, settings-view > ui-view, quota-settings-view > ui-view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

month-name {
  display: inline;
}

.minimum {
  width: 1%;
}

.minimum md-checkbox {
  margin: 0;
}

generate-quotas-input md-select {
  margin: 10px 0;
}

generate-quotas-input div[layout="row"]:first-child {
  margin-bottom: 20px;
}

generate-quotas-input div[layout="row"]:last-child {
  margin-top: 30px;
}

.tab-btn {
  border-bottom: solid 2px transparent;
  margin-bottom: 0;
}

.tab-btn.active {
  border-bottom-color: #006C94;
}

#payments-table tr:hover {
  background-color: rgba(238, 238, 238, 0.4);
  cursor: pointer;
}

#payments-table th:not(:first-child), #payments-table td:not(:first-child) {
  text-align: center;
  padding: 5px;
}

#payments-table th:first-child, #payments-table td:first-child {
  width: 1%;
  opacity: 0.5;
  white-space: nowrap;
}

.payment-table {
  background-color: #eee;
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 10px;
}

.payment-table th {
  text-align: right;
  padding-right: 5px;
  opacity: 0.5;
}

.payment-table td {
  text-align: left;
  padding-left: 5px;
  font-weight: 300;
  letter-spacing: 1px;
}

.payment-table td[colspan="2"] {
  text-align: center;
  opacity: 0.4;
  letter-spacing: 6px;
}

.payment-items-table {
  max-width: 500px;
  margin: 10px 0;
  opacity: 0.8;
}

.payment-items-table th, .payment-items-table td {
  padding: 0px 10px;
  border-bottom: solid 1px #ddd;
}

.payment-items-table th {
  font-weight: 300;
  text-align: left;
  padding-left: 5px;
  font-size: 12px;
}

.payment-items-table td {
  width: 1%;
  text-align: right;
}

quota-settings-view table th, quota-settings-view table td {
  padding: 10px 0;
}

quota-settings-view table tr input[type=text] {
  display: inline;
  width: 70px;
  text-align: center;
  padding: 5px 0;
  font-size: 15px;
  letter-spacing: -0.5px;
}

quota-settings-view table tr input[type=text][disabled] {
  border-color: transparent;
}

quota-settings-view table tr .edit-btn {
  opacity: 0;
}

quota-settings-view table tr:hover .edit-btn {
  opacity: 1;
}

quota-settings-view .highlight {
  background-color: rgba(22, 132, 113, 0.3);
}

